body.landing-gourmet-experience {
  padding: 0px;
}

.hero {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;
  background-color: white;
}

.hero-club-landing {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=375&height=527&image=https://global.cocunat.com/photos/club_022023_hero_mobile.png);
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 570px;
  padding: 20px;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
.hero-club-landing-2 .mobile{
  width: 100%; 
  height: auto; 
  object-fit: cover; 
  display: block;
}
.hero-club-landing-2 .desktop{
  display: none;
}
}
@media screen and (min-width: 768px) {
  .hero-club-landing-2 .desktop{
    width: 100%; 
    height: auto; 
    max-height: 550px;
    object-position: center bottom;
    object-fit: cover; 
    display: block;
  }
  .hero-club-landing-2 .mobile{
    display: none;
  }
  }
.hero-club-landing .title .title-1 {
  font-size: 40px;
  line-height: 45px;
  text-align: left;
  max-width: 270px;
  margin: 0px;
}
.hero-club-landing .title .title-2 {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.hero-club-landing .title .title-2 span.for {
  font-size: 20px;
  margin-bottom: 40px;
}
.hero-club-landing .title .title-2 span.currency-month {
  font-size: 28px;
  line-height: 64px;
  margin: 25px 0px;
  font-weight: 700;
}
.hero-club-landing .title .title-2 span.currency {
  font-size: 65px;
  line-height: 20px;
  text-align: left;
  display: block;
}

.hero-club-landing .title .title-2 span.price {
  font-size: 115px;
  line-height: 64px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.hero-club-landing .hero-buttons {
  display: flex;
  flex-direction: column;
}

.hero-club-landing .hero-buttons button {
  margin-bottom: 20px;
  font-weight: 400;
}

.middle-banner-club-landing {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=375&height=426&image=https://global.cocunat.com/photos/club_022023_banner_mobile_texture.png);
  background-size: contain;
  display: flex;
  background-color: #f1eee5;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px 20px 30px;
}

.middle-banner-club-landing .title .title-1 {
  text-align: center;
  margin: 0px;
}
.middle-banner-club-landing .title .title-2 {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0px;
}
.middle-banner-club-landing .title .title-2 span.for {
  font-size: 20px;
}
.middle-banner-club-landing .title .title-2 span.currency-month {
  font-size: 65px;
  line-height: 65px;
  font-weight: 700;
}
.middle-banner-club-landing .title .title-2 span.currency {
  font-size: 65px;
  line-height: 65px;
  text-align: left;
  display: block;
}

.middle-banner-club-landing .title .title-2 span.price {
  font-size: 147px;
  line-height: 110px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.promo-banner-club-landing {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=1080&height=1332&image=https://global.cocunat.com/photos/club_022023_banner_mobile_promo.jpg);
  background-size: cover;
  display: flex;
  background-color: #f1eee5;
  flex-direction: column;
  justify-content: start;
  padding: 100px 30px 100px 30px;
  align-items: center;
  height: 650px;
  background-position: center;
}

.promo-banner-club-landing p {
  margin: 0px;
}

.landing-club-022023 .promo-banner-club-landing .title-1 {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  font-family: "Brown-Light";
  margin: 0px;
}

.landing-club-022023 .promo-banner-club-landing .title-2 {
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  font-family: "Brown-Light";
  margin: 0px;
}

.landing-club-022023 .promo-banner-club-landing .title-2 span {
  font-family: "Brown-Regular";
  background-color: #f5e2e7;
}

.landing-club-022023 .promo-banner-club-landing .title-3 {
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  font-family: "Brown-Light";
  margin: 0px;
  max-width: 500px;
}

.club-advantages {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=375&height=527&image=https://global.cocunat.com/photos/club_022023_banner_mobile.png);
  background-size: cover;
  background-position: right top;
}

.club-press {
  background-color: #fefbf4;
}

.club-press img {
  margin: 10px auto 0px auto;
}

.club-advantages,
.club-howto,
.club-press,
.club-truspilot {
  padding: 20px 30px 40px 30px;
}

.club-products {
  padding: 20px 15px 40px 15px;
}

.club-products h2 {
  text-align: center !important;
  font-size: 22px !important;
  line-height: 28px;
  margin-bottom: 10px !important;
}

.club-products-link {
  color: black;
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
  line-height: 28px;
  margin: 20px auto;
  font-family: "Brown-Regular";
}
.club-truspilot .title img {
  display: block;
  margin: 10px auto;
}

.club-truspilot .title p {
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
}

.club-truspilot .comments {
  display: flex;
  flex-direction: column;
}

.club-truspilot .comments .comments-inner {
  text-align: left;
}

.club-truspilot .comments .comments-inner p.author {
  font-size: 22px;
  line-height: 28px;
  font-family: "Brown-Regular";
  margin-bottom: 5px;
}

.club-truspilot .comments .comments-inner p.comment {
  font-size: 16px;
  line-height: 24px;
  font-family: "Brown-Regular";
  margin-bottom: 5px;
}

.club-advantages .advantages-list .advantages-inner,
.club-howto .howto-list .howto-inner {
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  justify-content: flex-start;
  align-items: center;
}

.club-advantages .advantages-list .advantages-inner span,
.club-howto .howto-list .howto-inner span {
  font-size: 48px;
  line-height: 24px;
  align-items: center;
}
.club-advantages .advantages-list .advantages-inner .text,
.club-howto .howto-list .howto-inner .text {
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
}

.club-advantages .advantages-list .advantages-inner .line {
  border-bottom: 1px solid;
  width: 72px;
}

.club-price-tag {
  background-color: #ff0d0d;
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding: 5px;
  position: absolute;
  left: 0px;
  bottom: 13px;
}
.club-price-tag .strike-price {
  color: white !important;
}
.landing-gourmet-container {
  max-width: 550px;
  margin: auto;
}

#video-container {
  width: 100%;
  padding-top: 176%;
  position: relative;
}

.landing-gourmet-container .img-full {
  width: 100%;
}

.landing-club-container p.club_Title {
  font-size: 26px;
}

.header-progress-list {
  padding: 0;
  list-style-type: none;
  width: 100%;
  margin-top: 30px 0px 500px 0px;
}

.clubSteps {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.step {
  width: calc(100% - 30px);
  margin: 0px;
  text-align: left;
  padding: 0px 15px;
  display: flex;
  align-content: flex-start;
  margin-bottom: 15px;
}

.step span {
  font-family: "Brown-Regular";
  font-size: 24px;
  display: inline-block;
  margin-right: 5px;
}

.step p {
  display: inline-block;
  margin: 0px;
  line-height: 24px;
}
.header-progress-item {
  position: relative;
  display: inline-block;
  width: calc(100% / 4);
  text-align: left;
  line-height: 3em;
}

.header-progress-item:before {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: 0;
  height: 10px;
  width: 10px;
  border-radius: 1.2em;
  border: none;
  line-height: 1.2em;
  content: " ";
  background-color: black;
}

.header-progress-item:after {
  width: 40px;
  left: 0px;
  position: absolute;
  display: block;
  z-index: 1;
  top: -2px;
  left: 0px;
  height: 1px;
  width: 100%;
  content: "";
  background-color: black;
}

.header-progress-item:last-child::after {
  width: 0px;
}

/* ======================================= */
/* ============== CLUB LANDING =========== */
/* ======================================= */

#footer .pictures,
#footer .leadForm {
  display: none;
}

.ClubTitle {
  padding: 80px 15px 0px 15px;
}
.ClubTitle img,
.bannerFooter img {
  margin: auto;
}

.ClubTitle img {
  max-width: 100%;
}

.ClubTitle p.title1 {
  font-size: 22px;
}
.ClubTitle p.title2 {
  font-size: 22px;
  font-family: "Brown-Regular";
}
.ClubTitle p.title3 {
  font-size: 20px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}
.ClubTitle p.title4 {
  font-size: 20px;
  margin: 0px;
  text-align: left;
  padding-bottom: 20px;
}
.landing-club-container .section2,
.landing-club-container .section3 {
  overflow: hidden;
  position: relative;
}

.landing-club-container .section3 {
  background-color: #f3f3f3;
  padding: 15px 0px;
}
.landing-club-container h1 {
  font-family: "Brown-Regular";
  font-size: 28px;
  text-align: center;
  margin: 0px;
  font-weight: 100;
  padding: 10px 15px 0px 15px;
  text-transform: uppercase;
}

.landing-club-container h2 {
  font-family: "Brown-Light";
  font-size: 22px;
  text-align: left;
  margin: 0px;
  padding: 0px 15px;
}

.landing-club-container .HWInner,
.landing-club-container .howItWorks div {
  border-bottom: 0px;
}

.landing-club-container .howItWorks {
  max-width: 1050px;
  margin: auto;
}
.landing-club-container .HWInner p {
  font-size: 18px;
  margin: 0px;
}

.landing-club-container .col-xs-12 {
  padding: 0px;
}

.HWInner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.howItWorks img {
  margin-right: 10px;
}
.howItWorks div,
.faqs div.faqsInner {
  text-align: left;
  border-bottom: 1.5px solid black;
  padding: 10px 0px;
  margin: 0px;
}

.faqs {
  background-color: #f5f5f5;
  padding: 20px 15px 40px 15px;
}

.faqs div.faqsInner li {
  list-style: none;
}
.howItWorks div:last-child {
  border-bottom: 0px;
}

.faqs div.faqsInner .title {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
}

.faqs div.faqsInner .showMoreButton span {
  font-size: 32px;
  line-height: 24px;
}

.productInner {
  width: calc(100% - 30px);
  margin: 30px auto;
}
.productInner p.title {
  font-family: "Brown-Regular";
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.productInner p.title span {
  background: #fff;
  padding: 0 10px;
}

.faqs .title {
  font-family: "Brown-Regular";
}

.Clubproduct .LCProductInner {
  display: flex;
  justify-content: flex-start;
  margin: 10px 0px;
}
.Clubproduct .LCProductInner div {
  flex: 1 1 0px;
  text-align: left;
}

.Clubproduct .LCProductInner div.modal-screen {
  flex: unset;
  text-align: left;
}

.Clubproduct .LCProductInner div.modal-screen div {
  flex: unset;
  text-align: left;
}
.bannerFooter {
  background-color: #f0f1f2;
}

.truspilotTitle {
  margin: auto;
}

.Clubproduct .LCProductInner .innerTitle {
  font-family: "Brown-Regular";
}

.Clubproduct .LCProductInner:nth-child(odd) {
  flex-direction: row-reverse;
}

.Clubproduct .LCProductInner:nth-child(even) {
  flex-direction: row;
}

.Clubproduct li:before {
  content: "";
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=36&height=34&image=https://global.cocunat.com/web/list-icon-cocunat.png);
  background-size: 11px 11px;
  background-repeat: no-repeat;
  margin-right: 7px;
  margin-top: 7px;
  min-width: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 50%;
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 23px;
}
.Clubproduct li {
  list-style: none;
  display: flex;
}

#modalClub li {
  display: block;
}
.bsl {
  text-align: center;
  margin: auto;
  background-color: #fdb7ba;
}
.bsl img {
  margin: auto;
}
.mobile {
  display: block;
}

.desktop {
  display: none;
}

.truspilot {
  background-color: #e0e0e0;
  position: relative;
  overflow: hidden;
  padding: 30px 0px;
}

.truspilotComment {
  max-width: 375px;
}

.selectedTone {
  border: red solid 2px;
}

.accountRefill {
  padding: 0px;
}

.accountRefill .productInner .LCProductInner .price-product,
.accountRefill .productInner .LCProductInner button,
.accountRefill .productInner .LCProductInner .alreadyClub {
  display: none;
}
.accountRefill .productInner .LCProductInner {
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 8px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}

.accountRefill .Clubproduct {
  padding: 0px;
}

.accountRefill .productInner .LCProductInner button.close-button {
  display: block;
}
.accountRefill .productInner .LCProductInner .knowMore {
  color: #ff8172;
}

/* NEW */
.clubproduct-item {
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
  border: 2px solid #fff;
}

.clubproduct-item.selected {
  border: 2px solid #ff8172;
}
.clubproduct-item .triangle {
  width: 0;
  height: 0;
  border-top: 30px solid #ff8172;
  border-left: 30px solid transparent;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.clubUpsellContainer .controls {
  position: absolute;
  bottom: 90px;
  right: 20px;
  border: 1px solid #ddd;
  display: inline-flex;
  font-size: 12px;
  line-height: 27px;
  font-weight: 700;
  width: 60px;
}

.clubUpsellContainer .controls button {
  background-color: #fff;
  color: #000;
  border: 0;
  font-size: 30px;
  padding: 0 5px 7px 5px;
  height: 27px;
  line-height: 23px;
}

#floating-bottom {
  position: fixed;
  background-color: #fff;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.15);
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding-top: 8px;
  padding: 8px 25px 8px 25px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  z-index: 99999;
}

#floating-bottom button {
  max-width: 350px;
}
.clubUpsellContainer .product-item {
  margin-bottom: 0px;
}
.clubUpsellContainer .product-item,
.clubUpsellContainer .clubproduct-item {
  padding: 4px;
}
.clubUpsellContainer .productInner .cols {
  padding-left: 4px;
  padding-right: 4px;
}
.clubUpsellContainer .promo-sticker-product-loop {
  display: none;
}
.clubUpsellContainer .category-menu {
  list-style-type: none;
  background: #fff;
  position: sticky;
  top: 53px;
  left: 0px;
  z-index: 99;
  padding: 15px;
  text-align: center;
}
.clubUpsellContainer .category-menu li {
  display: inline-block;
  margin-right: 10px;
}
.clubUpsellContainer .product-item {
  width: 100%;
}
.clubUpsellContainer .product-item img {
  max-height: 355px;
  width: 100%;
}
.clubUpsellContainer .categoryContainer {
  overflow: hidden;
  clear: both;
}

.landing-club-022023 .hero-club-landing {
  background-size: cover;
}

.landing-club-022023 .header-club-logo {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.landing-club-022023 .header-club-logo img {
  height: 25px;
}

body.club-landing-02 {
  padding: 0px;
}

.landing-club-022023 .club-advantages .advantages-inner span {
  width: 20px;
  text-align: center;
}

.landing-club-022023 .club-advantages h1 {
  font-family: "Brown-Regular";
  font-size: 24px;
  margin-bottom: 20px;
}

.landing-club-022023 .club-advantages p {
  font-family: "Brown-Regular";
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.landing-club-022023 button.regular {
  height: 48;
  font-family: "Brown-Regular";
  font-size: 16px;
}

.landing-club-022023 button.add-club {
  height: 48;
  font-family: "Brown-Light";
  font-size: 14px;
}

.landing-club-022023 button.default {
  background: rgba(255, 255, 255, 0.6) !important;
  border-width: 2px;
}

.landing-club-022023 .club-advantages {
  padding-top: 40px;
  padding-bottom: 60px;
}

.landing-club-022023 .club-advantages .advantages-list .advantages-inner .text {
  margin-left: 35px;
}

.landing-club-022023 .faqs h1 {
  font-size: 24px;
  font-family: "Brown-Regular";
  margin-bottom: 40px;
}

.landing-club-022023 .faqs {
  padding-top: 55px;
  padding-bottom: 55px;
}

.landing-club-022023 .faqs .title {
  cursor: pointer;
}

.landing-club-022023 .faqs .text {
  padding: 10px 0px;
}

.landing-club-022023 .club-press h1 {
  font-family: "Brown-Regular";
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 0px;
}

.landing-club-022023 .club-press {
  padding-top: 40px;
  padding-bottom: 50px;
}

.landing-club-022023 .club-truspilot .comments .comments-inner {
  padding: 0px 10px;
}

.landing-club-022023 .club-truspilot .comments {
  margin-top: 30px;
}

.landing-club-022023 .club-truspilot .comments .comments-inner p.comment {
  font-size: 14px;
  line-height: 20px;
  font-family: "Brown-Regular";
  margin-bottom: 5px;
}

.landing-club-022023 .club-truspilot .title .score {
  display: flex;
  width: 100%;
  margin: 40px auto;
  justify-content: center;
  align-items: center;
}

.landing-club-022023 .club-truspilot .title .score h1 {
  font-size: 28px;
  font-family: "Brown-Regular";
  margin-bottom: 0px;
  margin-right: 10px;
}

.landing-club-022023 .club-truspilot .title .score span {
  font-size: 20px;
  font-family: "Brown-Regular";
  margin-bottom: 0px;
}

.landing-club-022023 .hero-club-landing .title .title-1 {
  margin-bottom: 40px;
}

.landing-club-022023 .hero-club-landing .title .title-2 .price {
  font-family: "Bebas Neue";
  font-size: 170px;
}

.landing-club-022023 .advantages-inner span,
.landing-club-022023 .howto-inner span {
  font-family: "Playfair Display";
}

.landing-club-022023 .howto-inner span {
  margin-bottom: 10px;
  font-size: 70px !important;
  line-height: 70px !important;
}

.landing-club-022023 .club-howto {
  padding: 55px 0px 65px 0px;
  max-width: 950px;
  margin: auto;
}

.landing-club-022023 .club-howto h1 {
  font-family: "Brown-Regular";
  font-size: 24px;
  margin-bottom: 10px;
}

.landing-club-022023 .middle-banner-club-landing {
  background-size: cover;
}

.landing-club-022023 .middle-banner-club-landing .title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landing-club-022023 .middle-banner-club-landing .title-1 {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  font-family: "Brown-Regular";
  margin: 0px;
  margin-bottom: 35px;
}

.landing-club-022023 .middle-banner-club-landing .price {
  font-family: "Bebas Neue";
  font-size: 170px;
}
.landing-club-022023 .middle-banner-club-landing .ending {
  margin: 0px;
}

.hero-club-landing .hero-buttons button:last-child {
  margin-bottom: 0px;
}

.modal-container:has(> div.modal-content > div.modalInnerAddClub)
  .close-button {
  top: -15px;
  right: 15px;
  background-color: transparent;
  color: black;
  font-family: "Brown-Light";
  font-size: 30px;
  padding: 30px;
}

.modal-content:has(> div.modalInnerAddClub) {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=341&height=496&image=https://global.cocunat.com/photos/club_022023_popp_mobile.png);
  background-size: 100% auto;
  padding: 50px 40px;
  background-position: center;
}

.modalInnerAddClub h1 {
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  padding: 0px !important;
  text-transform: none !important;
}
.modalInnerAddClub h2 {
  padding: 0px !important;
  font-size: 22px;
  line-height: 28px;
  font-family: "Brown-Light";
  text-align: left !important;
  margin: 20px 0px !important;
}

.modalInnerAddClub ul {
  text-align: left;
  padding-left: 0px;
  list-style: none;
  font-size: 16px;
  line-height: 24px;
}

.modalInnerAddClub small {
  text-align: left;
}

.modalInnerAddClub button span {
  font-size: 20px !important;
  line-height: 24px !important;
}

.club-products h2:first-of-type {
  margin-top: 40px;
}

.club-products h2:last-of-type {
  margin-bottom: 40px !important;
}
@media (min-width: 767px) {
  .landing-club-022023 .club-truspilot .comments .comments-inner {
    width: calc(100% / 3);
  }
  .landing-club-022023 .middle-banner-club-landing .title {
    flex-direction: row;
  }
  .landing-club-022023 .middle-banner-club-landing .title-1 {
    max-width: 500px;
    padding-right: 40px;
    font-size: 36px;
    line-height: 51px;
    font-family: "Brown-Light";
    margin: 0px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 0px;
  }

  .clubUpsellContainer .product-item img {
    width: auto;
  }
  .clubUpsellContainer .category-menu {
    /*top: 85px;*/
  }
  .clubUpsellContainer .product-item {
    margin-bottom: 0px;
  }
  .clubUpsellContainer .product-item,
  .clubUpsellContainer .clubproduct-item {
    padding: 4px;
  }
  .Clubproduct .LCProductInner {
    display: inline-flex;
    justify-content: flex-start;
    width: 50%;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .glide-carousel-truspilot-quotes-slide {
    display: none;
  }
  .productInner p.title {
    font-family: "Brown-Regular";
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .accountRefill .refillProductClud {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
  }

  .accountRefill > p {
    text-align: center;
  }

  .accountRefill .refillProductClud .LCProductInner {
    display: flex;
    width: 100%;
  }
  .clubUpsellContainer .controls {
    position: absolute;
    bottom: 90px;
    right: 20px;
    width: 75px;
  }
  .clubUpsellContainer .controls button {
    font-size: 30px;
    padding: 0 10px 7px 10px;
    line-height: 23px;
  }

  .ClubTitle section {
    max-width: 1050px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
  }

  .ClubTitle section div,
  .ClubTitle section img {
    width: 50%;
  }

  .ClubTitle p {
    text-align: left;
    margin: 30px 0px;
  }
  .ClubTitle p.title1 {
    font-size: 28px;
  }
  .ClubTitle p.title2 {
    font-size: 28px;
    font-family: "Brown-Regular";
  }
  .ClubTitle p.title3 {
    font-size: 22px;
    margin: 30px 0px;
    text-align: left;
  }
  .ClubTitle p.title4 {
    font-size: 22px;
    text-align: left;
    margin: 30px 0px;
    padding-bottom: 20px;
  }
  .landing-club-container p.club_Title {
    font-size: 32px;
  }

  .landing-club-container h1 {
    font-family: "Brown-Light";
    font-size: 32px;
    text-align: center;
    margin: 0px;
    font-weight: 100;
    padding: 0px;
  }

  .landing-club-container h2 {
    font-family: "Brown-Light";
    font-size: 22px;
    text-align: left;
    margin: 0px;
    padding: 0px;
  }

  .clubSteps {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }
  .step {
    width: 25%;
    margin: 0px;
    display: block;
    text-align: center;
  }

  .step span {
    font-family: "Brown-Regular";
    font-size: 24px;
    display: block;
  }

  .section3 img {
    position: absolute;
    left: 740px;
    z-index: 99;
    width: 410px;
    height: auto;
    top: 180px;
  }

  .landing-club-022023 {
    max-width: 1440px;
    margin: auto;
  }

  .hero {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: -70px;
    margin-bottom: 10px;
    background-color: white;
  }

  .hero-club-landing {
    background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=1440&height=408&image=https://global.cocunat.com/photos/club_022023_hero_desktop.png);
    background-size: contain;
    display: flex;
    flex-direction: column;
    height: 420px;
    padding: 35px 200px;
    justify-content: center;
  }

  .hero-club-landing .title .title-1 {
    font-size: 40px;
    line-height: 45px;
    text-align: left;
    max-width: 100%;
    margin: 0px;
  }
  .hero-club-landing .title .title-2 {
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: flex-end;
    margin: 0px;
  }
  .hero-club-landing .title .title-2 span.for {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .hero-club-landing .title .title-2 span.currency-month {
    font-size: 28px;
    line-height: 64px;
    margin: 25px 0px;
    font-weight: 700;
  }
  .hero-club-landing .title .title-2 span.currency {
    font-size: 65px;
    line-height: 20px;
    text-align: left;
    display: block;
  }

  .hero-club-landing .title .title-2 span.price {
    font-size: 115px;
    line-height: 75px;
    margin: 0px 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .hero-club-landing .hero-buttons {
    display: flex;
    flex-direction: column;
  }

  .hero-club-landing .hero-buttons button {
    margin-bottom: 20px;
    font-weight: 400;
    max-width: 300px;
  }

  .middle-banner-club-landing .hero-buttons button {
    margin-bottom: 20px;
    font-weight: 400;
    max-width: 300px;
  }

  .promo-banner-club-landing {
    background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=5232&height=835&image=https://global.cocunat.com/photos/club_022023_banner_desktop_promo.jpg);
    background-size: cover;
    display: flex;
    background-color: #f1eee5;
    flex-direction: column;
    justify-content: center;
    padding: 20px 150px 20px 150px;
    height: 230px;
    background-position: center;
  }

  .landing-club-022023 .promo-banner-club-landing .title-2 span {
    font-family: "Brown-Regular";
    background-color: #f5e2e7;
    font-size: 20px;
    line-height: 28px;
  }

  .middle-banner-club-landing {
    background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=1439&height=343&image=https://global.cocunat.com/photos/club_022023_banner_desktop.png);
    background-size: contain;
    display: flex;
    background-color: #f1eee5;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 150px 20px 150px;
  }

  .middle-banner-club-landing .title .title-1 {
    margin: 0px;
  }
  .middle-banner-club-landing .title .title-2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0px;
  }
  .middle-banner-club-landing .title .title-2 span.for {
    font-size: 20px;
  }
  .middle-banner-club-landing .title .title-2 span.currency-month {
    font-size: 65px;
    line-height: 65px;
    font-weight: 700;
  }
  .middle-banner-club-landing .title .title-2 span.currency {
    font-size: 65px;
    line-height: 65px;
    text-align: left;
    display: block;
  }

  .middle-banner-club-landing .title .title-2 span.price {
    font-size: 147px;
    line-height: 105px;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  .club-advantages {
    background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=840&height=388&image=https://global.cocunat.com/photos/club_022023_texture_desktop.png);
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
    background-color: #fefbf4;
    text-align: center;
  }

  .club-press {
    background-color: #fefbf4;
  }

  .club-press img {
    margin: 10px auto 0px auto;
    max-width: 100%;
  }

  .club-advantages,
  .club-howto,
  .club-press,
  .club-truspilot {
    padding: 20px 175px 40px 175px;
  }
  .club-truspilot .title img {
    display: block;
    margin: 10px auto;
  }

  .club-truspilot .title p {
    font-size: 22px;
    line-height: 28px;
    margin: 0px;
  }

  .club-truspilot .comments {
    display: flex;
    flex-direction: row;
  }

  .club-truspilot .comments .comments-inner {
    text-align: center;
  }

  .club-truspilot .comments .comments-inner p.author {
    font-size: 22px;
    line-height: 28px;
    font-family: "Brown-Regular";
    margin-bottom: 5px;
  }

  .club-truspilot .comments .comments-inner p.comment {
    font-size: 16px;
    line-height: 24px;
    font-family: "Brown-Regular";
    margin-bottom: 5px;
  }
  .club-howto .howto-list {
    display: flex;
    justify-content: space-between;
  }

  .club-howto .howto-list .howto-inner {
    display: flex;
    flex-direction: column;
    padding: 30px 20px 0px 20px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .club-howto .howto-list .howto-inner .text {
    text-align: left;
    margin-left: 0px;
    font-size: 20px;
  }
  .club-advantages .advantages-list .advantages-inner {
    display: flex;
    flex-direction: row;
    padding: 0px 20px;
    justify-content: flex-start;
    align-items: center;
  }

  .club-advantages .advantages-list .advantages-inner span,
  .club-howto .howto-list .howto-inner span {
    font-size: 48px;
    line-height: 24px;
    align-items: center;
  }
  .club-advantages .advantages-list .advantages-inner .text {
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
  }

  .club-advantages .advantages-list .advantages-inner .line {
    border-bottom: 1px solid;
    width: 212px;
  }

  .modal-container:has(> div.modal-content > div.modalInnerAddClub) {
    max-width: 750px;
  }
  .modal-content:has(> div.modalInnerAddClub) {
    background-image: url(https://resizing.cocunat.com/r/?quality=100&format=webp&fit=contain&width=732&height=471&image=https://global.cocunat.com/photos/club_022023_popp_desktop.png);
    background-size: cover;
    background-position: bottom;
    padding: 70px;
  }

  .modalInnerAddClub h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    padding: 0px !important;
    text-transform: none !important;
    font-family: "Brown-Regular";
    max-width: 350px;
  }
  .modalInnerAddClub h2 {
    padding: 0px !important;
    font-size: 22px;
    line-height: 28px;
    font-family: "Brown-Light";
    text-align: left !important;
    margin: 20px 0px !important;
  }

  .modalInnerAddClub ul {
    text-align: left;
    padding-left: 0px;
    list-style: none;
    font-size: 16px;
    line-height: 24px;
  }

  .modalInnerAddClub small {
    text-align: left;
  }

  .modalInnerAddClub button span {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .modalInnerAddClub > button {
    margin: 30px auto !important;
    max-width: 350px;
    display: block;
  }

  .club-products h2:first-of-type {
    margin-top: 50px;
  }

  .club-products h2:last-of-type {
    margin-bottom: 50px !important;
  }
}
