.vnext-ButtonGroup {
  padding-left: 1px;
  justify-content: center;
  margin-bottom: 50px;
}
.vnext-ButtonGroup_button {
  background-color: #fff;
  border: 1px solid #ff8172;
  color: #ff8172;
  padding: 10px 16px;
  margin-left: -1px;
  position: relative;
  font-weight: bold;
}

.vnext-ButtonGroup_button:first-child {
  border-radius: 6px 0px 0px 6px;
}

.vnext-ButtonGroup_button:last-child {
  border-radius: 0px 6px 6px 0px;
}

.vnext-ButtonGroup_button.active {
  color: #fff;
  background-color: #ff8172;
}

.vnext-ButtonGroup_button.active.two-lines span {
  font-size: 11px;
  display: block;
  position: absolute;
  top: 3px;
  left: 15px;
}
.vnext-ButtonGroup_button.active.two-lines > strong {
  display: block;
  margin-bottom: -12px;
}
