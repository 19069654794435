.account-payment-method {
  padding: 5px 0;
  border-bottom: 1px solid #444;
  display: block;
  color: #000;
}

.account-payment-method-button {
  margin: 15px;
}

.payment-method-header {
  font-weight: bold;
  text-align: left;
  text-transform: capitalize;
}

.payment-method-expiration {
  text-align: left;
  color: #666;
}

.payment-method-default {
  font-size: 0.9em;
  margin: 0 5px;
  padding: 5px;
  border-radius: 5px;
  background-color: #fecec2;
}

.payment-method-button {
  float: right;
  position: relative;
}

.payment-method-month,
.payment-method-year {
  border: 1px solid #eaeaea;
  color: #737373;
  border-radius: 4px;
  padding: 0 5px;
  margin: 0 5px;
  background-color: #fff;
  width: 30px;
}

.payment-method-year {
  width: 50px;
}

.payment-method-edit {
  text-align: left;
}

.payment-method-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.payment-method-buttons button {
  background-color: #000;
}
